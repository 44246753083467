<template>
    <div class="drag-dialog-compontent" v-drag="'#'+dragId" 
        :style="(width ? `width:${width}px;` : '') + (top ? `top:${top}px;` : '') + (left ? `left:${left}px;` : '') + (right ? `right:${right}px;` : '')"
        >
        <div class="title-content" :id="dragId">
            <span>{{title}}</span>
            <div class="close" @click="closeEvent">
                <i class="el-icon-close"></i>
            </div>
        </div>
        <div class="body-content">
            <slot></slot>
        </div>
        <div class="footer-content">
            <slot name="footer"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        title:{
            type:String,
            default: function () {
                return this.$t('component.DragDialog_title');
            },
        },
        top:{
            type: Number,
            default: 0,
        },
        left:{
            type: Number,
            default: 0,
        },
        right:{
            type: Number,
            default: 0,
        },
        width:{
            type:Number,
            default:350,
        }
    },
    data(){
        return {
            dragId: `drag-dialog-title-content-${new Date().getTime()}`,
        }
    },
    methods:{

        closeEvent(){
            this.$emit('close');
        }
    }
}
</script>
<style lang="scss" scoped>
.drag-dialog-compontent{
    position: absolute;
    // left: 20%;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #E4E7ED;
    box-sizing: border-box;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
    z-index: 100;
    .title-content{
        border-bottom: 1px solid #0000001A;
        box-sizing: border-box;
        padding: 0 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        position: relative;
        font-size: 15px;
        cursor: move;
        user-select: none;
        font-weight: 500;
        > .close{
            position: absolute;
            top: 0;
            right: 0;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: center;
            cursor: pointer;
        }
    }
    .body-content{
        padding: 20px 0 10px 0;
        .el-form-item{
            margin-bottom: 10px;
        }
    }
    .footer-content{
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}
</style>
<style lang="scss">
.drag-dialog-compontent{
    .body-content{
        .el-form-item{
            margin-bottom: 10px;
        }
    }
}
</style>
